function TodoItem({name}){

    return(
        <li>
            {name}

        </li>
    )

}
export default TodoItem;